








































































































































































import { Component, Vue } from 'vue-property-decorator'
import Dialog from '../../shared/src/components/Dialog.vue'
import Card from '../../shared/src/components/Card.vue'
import Col from '../../shared/src/components/Col.vue'
import Row from '../../shared/src/components/Row.vue'
import Btn from '../../shared/src/components/Btn.vue'
import Logo from '../components/Logo.vue'
import ContentRow from '../components/ContentRow.vue'
import Sizzle from '../components/Sizzle.vue'

type CourtTypes = 'courtOfTheMonth' | 'pickleball' | 'tennis' | 'basketball' | 'volleyball' | 'multicourt'

@Component({
  components: {
    Sizzle,
    Dialog,
    Card,
    Col,
    Row,
    Btn,
    Logo,
    ContentRow
  }
})
export default class Gallery extends Vue {
  groupId: CourtTypes = 'pickleball'
  imgIndex = -1
  displayImage = ''
  courtOfTheMonth = []

  featured = [
    'assets/gallery/pickleball/2022/pickleball-1-thumb.webp',
    'assets/gallery/pickleball/2022/pickleball-2-thumb.webp',
    'assets/gallery/pickleball/2022/pickleball-3-thumb.webp',
    // 'assets/gallery/pickleball/2022/pickleball-4-thumb.webp', // not good enough
    'assets/gallery/pickleball/2022/pickleball-5-thumb.webp',
    // 'assets/gallery/pickleball/2022/pickleball-6-thumb.webp',
    'assets/gallery/pickleball/2022/pickleball-7-thumb.webp',
    'assets/gallery/pickleball/2022/pickleball-8-thumb.webp',
    'assets/gallery/pickleball/2022/pickleball-9-thumb.webp',
    // 'assets/gallery/pickleball/2022/pickleball-10-thumb.webp',
    'assets/gallery/pickleball/2022/pickleball-11-thumb.webp'
  ]

  pickleball = [
    'assets/gallery/pickleball/pickleball-1-thumb.webp',
    'assets/gallery/pickleball/pickleball-2-thumb.webp',
    'assets/gallery/pickleball/pickleball-3-thumb.webp',
    'assets/gallery/pickleball/pickleball-4-thumb.webp',
    'assets/gallery/pickleball/pickleball-5-thumb.webp',
    'assets/gallery/pickleball/pickleball-6-thumb.webp',
    'assets/gallery/pickleball/pickleball-7-thumb.webp',
    'assets/gallery/pickleball/pickleball-8-thumb.webp',
    'assets/gallery/pickleball/pickleball-9-thumb.webp',
    'assets/gallery/pickleball/pickleball-10-thumb.webp',
    'assets/gallery/pickleball/pickleball-11-thumb.webp',
    'assets/gallery/pickleball/pickleball-12-thumb.webp',
    'assets/gallery/pickleball/pickleball-13-thumb.webp',
    'assets/gallery/pickleball/pickleball-14-thumb.webp'
  ]

  tennis = [
    'assets/gallery/tennis/tennis-court-surface-4-thumb.webp',
    'assets/gallery/tennis/tennis-court-surface-5-thumb.webp',
    'assets/gallery/tennis/tennis-court-surface-6-thumb.webp',
    'assets/gallery/tennis/tennis-court-surface-7-thumb.webp',
    'assets/gallery/tennis/tennis-court-surface-9-thumb.webp',
    'assets/gallery/tennis/tennis-court-surface-10-thumb.webp',
    'assets/gallery/tennis/tennis-court-surface-11-thumb.webp',
    'assets/gallery/tennis/tennis-court-surface-12-thumb.webp',
    'assets/gallery/tennis/tennis-court-surface-13-thumb.webp',
    'assets/gallery/tennis/tennis-court-surface-14-thumb.webp',
    'assets/gallery/tennis/tennis-court-surface-16-thumb.webp',
    'assets/gallery/tennis/tennis-court-surface-18-thumb.webp',
    'assets/gallery/tennis/tennis-court-surface-20-thumb.webp',
    'assets/gallery/tennis/tennis-court-surface-22-thumb.webp',
    'assets/gallery/tennis/tennis-court-surface-23-thumb.webp',
    'assets/gallery/tennis/tennis-court-surface-24-thumb.webp'
  ]

  basketball = [
    'assets/gallery/basketball/basketball-1-thumb.webp',
    'assets/gallery/basketball/basketball-2-thumb.webp',
    'assets/gallery/basketball/basketball-3-thumb.webp',
    'assets/gallery/basketball/basketball-4-thumb.webp',
    'assets/gallery/basketball/basketball-5-thumb.webp',
    'assets/gallery/basketball/basketball-6-thumb.webp',
    'assets/gallery/basketball/basketball-7-thumb.webp'
  ]

  volleyball = [
    'assets/gallery/volleyball/volleyball-1-thumb.webp',
    'assets/gallery/volleyball/volleyball-2-thumb.webp',
    'assets/gallery/volleyball/volleyball-3-thumb.webp',
    'assets/gallery/volleyball/volleyball-4-thumb.webp',
    'assets/gallery/volleyball/volleyball-5-thumb.webp',
    'assets/gallery/volleyball/volleyball-6-thumb.webp',
    'assets/gallery/volleyball/volleyball-7-thumb.webp',
    'assets/gallery/volleyball/volleyball-8-thumb.webp'
  ]

  multicourt = [
    'assets/gallery/multicourt/multicourt-0-thumb.webp',
    'assets/gallery/multicourt/multicourt-1-thumb.webp',
    'assets/gallery/multicourt/multicourt-2-thumb.webp',
    'assets/gallery/multicourt/multicourt-3-thumb.webp',
    'assets/gallery/multicourt/multicourt-4-thumb.webp',
    'assets/gallery/multicourt/multicourt-5-thumb.webp',
    'assets/gallery/multicourt/multicourt-6-thumb.webp',
    'assets/gallery/multicourt/multicourt-7-thumb.webp'
  ]

  setDisplayIndex(groupId: CourtTypes, index: number): void {
    this.groupId = groupId
    this.imgIndex = index
    this.displayImage = this[this.groupId][index].replace('-thumb.webp', '.jpg')
  }
  prev(): void {
    let index = this.imgIndex - 1
    if (index <= 0) {
      index = this[this.groupId].length - 1
    }
    this.setDisplayIndex(this.groupId, index)
  }
  next(): void {
    let index = this.imgIndex + 1
    if (index > this[this.groupId].length - 1) {
      index = 0
    }
    this.setDisplayIndex(this.groupId, index)
  }
}
