
























import { Vue, Component, Prop } from 'vue-property-decorator'
import Bar from './Bar.vue'
import Col from './Col.vue'
import Btn from './Btn.vue'

@Component({
  components: {
    Col,
    Bar,
    Btn
  }
})
export default class Dialog extends Vue {
  @Prop({ default: false }) open!: boolean

  scrollTop: number = 0

  destryList: { event: string; fn: (e: Event) => void }[] = []

  mounted(): void {
    const onScroll = () => {
      this.scrollTop = window.scrollY
    }
    window.addEventListener('scroll', onScroll)
    this.destryList.push({ event: 'scroll', fn: onScroll })
    onScroll()
  }

  destroyed(): void {
    this.destryList.map((item) => {
      window.removeEventListener(item.event, item.fn)
    })
  }
}
